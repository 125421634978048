module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-intercom-spa/gatsby-browser.js'),
      options: {"plugins":[],"app_id":"oqs18aw2","include_in_development":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-launchdarkly/gatsby-browser.js'),
      options: {"plugins":[],"clientSideID":"5937bbffef6cd809f1571903","options":{"bootstrap":"localstorage"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
