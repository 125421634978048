import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import get from 'lodash/get'

export const PreviewAgencyContext = React.createContext()

function _usePreviewAgency() {
  return React.useContext(PreviewAgencyContext)
}

function _useAgency() {
  const data = useStaticQuery(graphql`
    query {
      agency {
        id
        name
        locale
        photo {
          data {
            photo_file_id
          }
        }
        settings {
          data {
            agency_website_hostname
            email
            contact_email
            contact_phone
            contact_city
            contact_street
            contact_number
            contact_zip
            registration_number
            links_facebook
            links_instagram
            links_linkedin
            links_twitter
            rent_period
            default_locale
            languages
            signup_country
            homepage_properties
            homepage_highlights {
              ...PropertyCard
            }
            homepage_cover
            homepage_cover_image
            homepage_cover_video
            new_properties_nl
            new_properties_fr
            new_properties_en
            about_en
            about_fr
            about_nl
            description_en
            description_nl
            description_fr
            tagline_nl
            tagline_fr
            tagline_en
            # contact
            contact_zip
            contact_borough_or_district
            contact_street
            contact_number
            contact_box
            contact_zip
            contact_city
            contact_country
            transaction_types
            # theming
            theme
            theme_custom_color
            livechat_app_id
          }
        }
        available_currencies {
          data
        }
      }
    }
  `)
  const agency = get(data, 'agency')
  const settings = {
    ...get(agency, 'settings.data'),
  }

  return {
    agency,
    settings,
    currencies: get(agency, 'available_currencies.data', []),
  }
}

function getUseAgencyHook() {
  if (typeof window !== 'undefined') {
    if (window.__PREVIEW__) {
      return _usePreviewAgency
    }
  }
  return _useAgency
}

export const useAgency = getUseAgencyHook()
